<template>
  <div class="block block__center" style="margin-bottom: 25px;">
    <div class="home__first_container block__relative">
      <div class="order__left_container">
        <div class="order__title">
          {{text.title[language] || ''}}
        </div>
        <div class="order__subtitle">
          {{text.subtitle[language] || ''}}
        </div>
        <div class="order__info">
          {{text.info[language] || ''}}
        </div>
        <div v-if="width <= t_lg" class="order__slider" :style="{height: `${sliderHeight}px`}">
          <div class="order__slider_container"
               :style="{top: `${sliderTop}px`}">
            <Slider :index="activeImage" :startTimer="startTimer" :stopTimer="stopTimer"
                    v-on:setActiveImage="setActiveImage($event)"/>
          </div>
        </div>
        <div class="order__upload">
          <FileUploadI5 />
        </div>
      </div>
      <div class="order__right_container" v-if="width > t_lg">
        <div class="order__background">
          <img :class="`order__detail_1 ${showPicture1 ? 'order__detail_1_show' : ''}`"
               src="@/assets/pictures/order/lastVers/detail1_clear.webp" rel="preload" />
          <img :class="`order__detail_2 ${showPicture2 ? 'order__detail_2_show' : ''}`"
               src="@/assets/pictures/order/lastVers/detail2_clear.webp" rel="preload" />
          <img :class="`order__detail_3 ${showPicture3 ? 'order__detail_3_show' : ''}`"
               src="@/assets/pictures/order/lastVers/detail3_clear.webp" rel="preload" />
          <img :class="`order__detail_4 ${showPicture4 ? 'order__detail_4_show' : ''}`"
               src="@/assets/pictures/order/lastVers/detail4_clear.webp" rel="preload" />
          <img src="@/assets/pictures/order/background.png"
               rel="preload"
               :class="`order__background_image
               ${showBackground ? 'order__background_image_show' : ''}`" />
          <img src="@/assets/pictures/order/lastVers/detail1.webp"
               rel="preload"
               :class="`order__detail_5
               ${showPicture5 ? 'order__detail_5_show' : ''}`" />
          <img src="@/assets/pictures/order/lastVers/detail2.webp"
               rel="preload"
               :class="`order__detail_6
               ${showPicture6 ? 'order__detail_6_show' : ''}`" />
          <img src="@/assets/pictures/order/lastVers/detail3.webp"
               rel="preload"
               :class="`order__detail_7
               ${showPicture7 ? 'order__detail_7_show' : ''}`" />
          <img src="@/assets/pictures/order/lastVers/detail4.webp"
               rel="preload"
               :class="`order__detail_8
               ${showPicture8 ? 'order__detail_8_show' : ''}`" />
        </div>
        <div class="block block__center block__align-center">
          <div :class="`
          block
          block__between
          block__align-center
          order__button_wrapper
          ${showButtons ? 'order__button_wrapper_show' : ''}
          `">
            <div @click="pushToTest(testDetail[0])">
              <ButtonCard :startTimer="startTimer" :stopTimer="stopTimer" :index="1"
                          :images-length="images.length"
                          v-on:setActiveImage="setActiveImage($event)">
                <template v-slot:img>
                  <img src="@/assets/pictures/order/lastVers/detail1_crop.webp"
                       rel="preload"
                       class="order__button_img order__button_img1" />
                </template>
              </ButtonCard>
            </div>
            <div @click="pushToTest(testDetail[1])">
              <ButtonCard :startTimer="startTimer" :stopTimer="stopTimer" :index="2"
                          :images-length="images.length"
                          v-on:setActiveImage="setActiveImage($event)">
                <template v-slot:img>
                  <img src="@/assets/pictures/order/lastVers/detail2_crop.webp"
                       rel="preload"
                       class="order__button_img order__button_img2" />
                </template>
              </ButtonCard>
            </div>
            <div @click="pushToTest(testDetail[2])">
              <ButtonCard :startTimer="startTimer" :stopTimer="stopTimer" :index="3"
                          :images-length="images.length"
                          v-on:setActiveImage="setActiveImage($event)">
                <template v-slot:img>
                  <img src="@/assets/pictures/order/lastVers/detail3_crop.webp"
                       rel="preload"
                       class="order__button_img order__button_img3" />
                </template>
              </ButtonCard>
            </div>
            <div @click="pushToTest(testDetail[3])">
              <ButtonCard :startTimer="startTimer" :stopTimer="stopTimer" :index="4"
                          :images-length="images.length"
                          v-on:setActiveImage="setActiveImage($event)">
                <template v-slot:img>
                  <img src="@/assets/pictures/order/lastVers/detail4_crop.webp"
                       rel="preload"
                       class="order__button_img order__button_img4" />
                </template>
              </ButtonCard>
            </div>
          </div>
        </div>
        <div class="order__button_info">
          {{text.button_info[language] || ''}}
        </div>
      </div>
    </div>
  </div>
  <!--<div class="block block__center">
    <div class="home__container block__relative">
    </div>
  </div>-->
</template>

<script>
import text from '@/assets/text/home.json';
import { query } from '@/utilites/axios';
import func from '@/utilites/functions';
import FileUploadI5 from '@/components/fileUploadI5/FileUploadI5.vue';

const ButtonCard = () => import('@/pages/home/components/ButtonCard.vue');
const Slider = () => import('@/components/slider/Slider.vue');

export default {
  name: 'OrderComponent',
  data() {
    return {
      text,
      func,
      timer: null,
      activeImage: 0,
      uploadPercent: null,
      images: [0, 1, 2, 3, 4],
      testDetail: [
        'be0a15b8-7c1a-4c0c-996a-1ae0d8a3f3c9',
        '148e4a6c-21a1-4573-9760-7f018cb4ab0a',
        '9388d8f0-8bac-4bef-abd5-c4c29deb36de',
        '956f330f-82af-4f51-8177-c6678dde52ac',
      ],
      showButtons: false,
      showBackground: false,
      showPicture1: false,
      showPicture2: false,
      showPicture3: false,
      showPicture4: false,
      showPicture5: false,
      showPicture6: false,
      showPicture7: false,
      showPicture8: false,
      sliderTop: 0,
      sliderHeight: 400,
    };
  },
  computed: {
    m_lg() {
      return this.$store.state.index.m_lg;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    language() {
      return this.$store.state.index.language;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  methods: {
    // Функция для поэтапной загрузки первого блока изображений
    showPictures() {
      setTimeout(() => {
        this.showBackground = true;
      }, 1);
      setTimeout(() => {
        this.showPicture1 = true;
      }, 150);
      setTimeout(() => {
        this.showPicture2 = true;
      }, 300);
      setTimeout(() => {
        this.showPicture3 = true;
      }, 450);
      setTimeout(() => {
        this.showPicture4 = true;
      }, 600);
      setTimeout(() => {
        this.showButtons = true;
      }, 750);
    },
    // Функция старта таймера, запускается при старте компонента принимает три параметра:
    // индекс изображения, задержка, и повторяемость. По истечению таймера в activeImage
    // запишется индекс картинки и вызовется функция отображения изображений
    startTimer(index, timeout, reset) {
      this.timer = setTimeout(() => {
        this.activeImage = index;
        this.$store.commit('home/setActiveImage', index);
        this.showImages(index);
        // Перезапускается только если reset = true
        if (reset) {
          this.resetTimer();
        }
      }, timeout);
    },
    // Останавливает таймер, если вызвать событие внутри таймера не произойдет
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    // Перезапуск таймера
    resetTimer() {
      if (this.timer) {
        // Останавливает
        this.stopTimer();
        // Тут мы проходимся по списку изображений, если длина не привышет, то в старт таймер пере-
        // дадим следующий индекс изображений, в противном случае передаем начальный.
        if (this.activeImage < this.images.length - 1) {
          this.startTimer(this.activeImage + 1, 5000, true);
        } else {
          this.startTimer(0, 5000, true);
        }
      }
    },
    // Функция которая срабатывает если мы навели мышь на карточку
    setActiveImage(index) {
      // Останавливает таймер
      this.stopTimer();
      // И стартует новый, но без повторения
      // Сделано для того чтобы картинки не менялись слишком быстро на любое движени мыши
      this.startTimer(index, 1000, false);
    },
    setActiveImageMobile(index) {
      this.activeImage = index;
    },
    pushToTest(data) {
      this.$router.push(`/test-order-offers/${data}`);
    },
    // Функция контролирует отображение картинок
    showImages(index) {
      // Выключает или включает первый блок каритнок
      if (index !== 0) {
        this.showPicture1 = false;
        this.showPicture2 = false;
        this.showPicture3 = false;
        this.showPicture4 = false;
      } else {
        this.showPicture1 = true;
        this.showPicture2 = true;
        this.showPicture3 = true;
        this.showPicture4 = true;
      }
      if (index !== 1) {
        this.showPicture5 = false;
      } else {
        this.showPicture5 = true;
      }
      if (index !== 2) {
        this.showPicture6 = false;
      } else {
        this.showPicture6 = true;
      }
      if (index !== 3) {
        this.showPicture7 = false;
      } else {
        this.showPicture7 = true;
      }
      if (index !== 4) {
        this.showPicture8 = false;
      } else {
        this.showPicture8 = true;
      }
    },
    // Функция управляет положение слайдера на мобильной версии
    setSliderPosition(width) {
      if (width <= this.m_lg) {
        const x = (this.m_lg - width) * 0.7;
        this.sliderHeight = 400 - x;
        this.sliderTop = -x;
      } else {
        this.sliderHeight = 450;
        this.sliderTop = -50;
      }
    },
    test() {
      console.log('');
    },
    uploadFile(formData, index, name) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        if (token) {
          query('put', 'addFile', formData, {
            token,
          }, (e) => {
            const percent = String(Math.round((e.loaded * 100) / e.total));
            this.uploadPercent = percent;
          }).then((item) => {
            query('post', 'addNewPartV2', {
              code: '1',
              name: name,
              files: [],
            }, {
              token: localStorage.getItem('token'),
            }).then(async (result) => {
              let orderUuid = null;
              let orderPartUuid = null;
              await query('post', 'addNewOrderV2', {
                parts: [
                  result.uuid,
                ],
              }, {
                token: localStorage.getItem('token'),
              }).then((order) => {
                orderUuid = order.uuid;
                orderPartUuid = order.order_spec[0];
              });
              await query('post', 'addFilesToOrderPartV2', {
                uuid: orderPartUuid,
                files: [
                  {
                    hash: item.hash,
                    time_stamp: item.timeStamp,
                    file_name: item.fileName,
                  },
                ],
              }).then(() => {
                this.func.routerPush(`/edit-order/${orderUuid}`);
              });
            });
            resolve(item);
          }).catch((err) => {
            if (err.response.status === 401) {
              this.isShowAuth = true;
            }
            reject(err);
          });
        } else {
          this.isShowAuth = true;
          reject();
        }
      });
    },
  },
  mounted() {
    this.$store.watch((state) => state.index.width, () => {
      this.setSliderPosition(this.$store.state.index.width);
      if (this.$store.state.index.width <= this.t_lg) {
        this.activeImage = 0;
        this.images = [0, 1, 2, 3];
        this.resetTimer();
      } else {
        this.activeImage = 0;
        this.images = [0, 1, 2, 3, 4];
        this.resetTimer();
      }
    });
    this.setSliderPosition(this.$store.state.index.width);
    if (this.$store.state.index.width <= this.t_lg) {
      this.activeImage = 0;
      this.images = [0, 1, 2, 3];
    } else {
      this.activeImage = 0;
      this.images = [0, 1, 2, 3, 4];
    }
    this.showPictures();
    this.startTimer(this.activeImage + 1, 5000, true);
  },
  beforeDestroy() {
    this.stopTimer();
  },
  components: {
    ButtonCard,
    Slider,
    FileUploadI5,
  },
};
</script>
